import React, { useState, useEffect } from 'react';

import config from './../config';

export default function HeimildView(props) {
	const [heimildDetail, setHeimildDetail] = useState('');

	let prevHeimild = '';

	useEffect(() => {
		if (props.heimild) {
			fetch(config.apiRoot+'/api/skammst/heimild?skammst='+props.heimild+(props.faersla ? '&faersla='+props.faersla : ''))
				.then((response) => response.json())
				.then((json) => {
					if (json.results.length > 0) {
						setHeimildDetail(json.results[0].skyring)
					}
				})
		}
	}, [props.heimild, props.faersla]);

	return heimildDetail
}
