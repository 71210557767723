import React, { Component, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import FaerslaView from './FaerslaView';

function SearchResultItem(props) {
	const [mouseOver, setMouseOver] = useState(false);
	const history = useHistory();

	return <div id={'faersla-'+props.item.id} className={'result-item'+(mouseOver ? ' hover' : '')}
		onMouseEnter={() => {
			setMouseOver(true);
		}}
		onMouseLeave={() => {
			setMouseOver(false);
		}}
		onClick={(event) => {
			event.preventDefault();
			let currentPath =history.location.pathname;

			history.replace(currentPath+history.location.search+'#listi/'+props.item.id);

			history.push('/faersla/'+props.item.id+(props.highlight ? '/merkja/'+props.highlight : ''));
		}}>
		<FaerslaView data={props.item} highlight={props.highlight} preview={props.preview} />
	</div>
}

export default SearchResultItem;
