import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import config from './../config';

import ToggleSwitch from './ToggleSwitch';
import StickyWatcher from './StickyWatcher';
import AutocompleteInput from './AutocompleteInput';
import helpers from './../helpers';

import searchIcon from './../images/icon-search.svg';

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.inputKeyDownHandler = this.inputKeyDownHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);
		this.formatAutocompleteList = this.formatAutocompleteList.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);

		this.state = {
			searchBoxInput: '',
			searchSvaedi: '',
			searchOrdflokkur: '',
			searchTextInput: false,
			advanced: false
		};
	}

	componentDidMount() {
		var stateObj = this.state;

		if (this.props.searchString) {
			stateObj.searchBoxInput = this.props.searchString || '';
		}
		if (this.props.beygingarmynd) {
			stateObj.searchTextInput = this.props.beygingarmynd;
		}

		this.setState(stateObj);

		this.refs.searchInput.refs.inputField.focus();

		setTimeout(function() {
			this.inputFocusHandler();
		}.bind(this), 100);
	}

	componentDidUpdate(prevProps, prevState) {
		var stateObj = this.state;

		var stateChanged = false;

		if (this.props.searchString != prevProps.searchString) {
			stateObj.searchBoxInput = this.props.searchString || '';

			stateChanged = true;
		}

		if (this.props.beygingarmynd != prevProps.beygingarmynd) {
			stateObj.searchTextInput =this.props.beygingarmynd;

			stateChanged = true;
		}

		if (stateChanged) {
			this.setState(stateObj);
		}
	}

	inputChangeHandler(event) {
		let value = event.target.type && event.target.type == 'checkbox' ? event.target.checked : event.target.value;

		var stateObj = {};
		stateObj[event.target.name] = value;

		this.setState(stateObj);
	}

	inputKeyDownHandler(event) {
		if (event.keyCode == 13) { // enter
			this.executeSearch();
		}
	}

	inputFocusHandler() {
		if (this.refs.searchInput) {
			this.refs.searchInput.refs.inputField.select();
		}
	}

	executeSearch() {
		if (this.state.searchBoxInput != '' ||
			this.state.searchSvaedi != '' ||
			this.state.searchOrdflokkur != '' ||
			this.state.sermerkingUrelt ||
			this.state.sermerkingNyyrdi ||
			this.state.sermerkingTokuorð ||
			this.state.sermerkingSkaldamal ||
			this.state.sermerkingDaglegtmal
		) {
			let searchParams = {};

			let sermerking = [];

			if (this.state.sermerkingUrelt) {
				sermerking.push('urelt');
			}
			if (this.state.sermerkingNyyrdi) {
				sermerking.push('nyyrdi');
			}
			if (this.state.sermerkingTokuorð) {
				sermerking.push('tokuord');
			}
			if (this.state.sermerkingSkaldamal) {
				sermerking.push('skaldamal');
			}
			if (this.state.sermerkingDaglegtmal) {
				sermerking.push('daglegtmal');
			}

			if (this.state.searchTextInput) {
				searchParams.texti = this.state.searchBoxInput;
			}
			else {
				if (this.state.searchBoxInput) {
					searchParams.leit = this.state.searchBoxInput;
				}
			}
			if (this.state.searchSvaedi) {
				searchParams.svaedi = this.state.searchSvaedi;
			}
			if (this.state.searchOrdflokkur) {
				searchParams.ordflokkur = this.state.searchOrdflokkur;
			}
			if (sermerking.length > 0) {
				searchParams.sermerkingar = sermerking.join(';');
			}

			/*
			this.props.history.push(
				'/leit/?'+(this.state.searchTextInput ? 'texti/' : '')+
				(this.state.searchBoxInput || '')+
				(this.state.searchSvaedi != '' ? '/svaedi/'+this.state.searchSvaedi : '')+
				(this.state.searchOrdflokkur != '' ? '/ordflokkur/'+this.state.searchOrdflokkur : '')+
				(sermerking.length > 0 ? '/sermerkingar/'+sermerking.join(';') : '')
			);
			*/
			//history.push('/leit/'+helpers.updateSearchParams(props.searchParams, event));
			this.props.history.push('/leit/'+helpers.searchParamString(searchParams));
		}
		else {
			this.refs.searchInput.flashError('Þú verður að slá inn leitarorð');
		}
	}

	searchBoxItemSelectHandler(item) {
		this.props.history.push('/leit/?leit='+item.leitarord);
	}

	formatAutocompleteList(item) {
		return <div className="pt-2 pb-2">
			<div>{item.leitarord}</div>
		</div>;
	}

	toggleAdvanced() {
		if (this.state.advanced) {
			this.setState({
				advanced: false,
				searchSvaedi:'',
				searchOrdflokkur:'',
				sermerkingUrelt: false,
				sermerkingNyyrdi: false,
				sermerkingTokuorð: false,
				sermerkingSkaldamal: false,
				sermerkingDaglegtmal: false
			})
		}
		else {
			this.setState({
				advanced: true
			});
		}
	}

	render() {
		let specialChars = ['ø', 'å'];

		let specialCharLinks = specialChars.map(function(char) {
			return <a key={char} onClick={function(event) {
				event.preventDefault();

				this.setState({
					searchBoxInput: this.state.searchBoxInput+char
				});
			}.bind(this)}>{char}</a>
		}.bind(this));

		return (
			<React.Fragment>
				{/*<StickyWatcher className={'search-form '+(this.props.className || '')} stickyClassName="sticky" useBodyTag={true}>*/}
				<div className={'search-form '+(this.props.className || '')}>

					<div className="col-12 col-md-12 col-lg-12 col-xl-12">
						<div className={'form-group column-content'+(!this.state.advanced ? ' mb-3' : '')}>

							<div className="d-flex">
								<div className=" flex-grow-1 mr-2 mr-sm-4">
									<AutocompleteInput ref="searchInput"
										inputClassName="form-control"
										responseDataField="results"
										searchUrl={config.apiRoot+'/api/leit_ordafordi/?leit=$s*'}
										onChange={this.inputChangeHandler}
										inputName="searchBoxInput"
										value={this.state.searchBoxInput}
										type="text"
										valueField="leitarord"
										onEnter={this.executeSearch}
										onItemSelect={this.searchBoxItemSelectHandler}
										placeholder="Leit í orðabók"
										minChars={2}
										selectedItemClass="active"
										disableAutoFill={false}
										headerText="Veldu flettu úr lista eða ýttu á enter til að leita."
										listLabelFormatFunc={this.formatAutocompleteList.bind(this)} />

								</div>

								<button className="btn btn-secondary mr-2" onClick={() => this.toggleAdvanced()}>Ítarleit</button>

								<button onClick={this.executeSearch.bind(this)} className="btn btn-primary">
									<span className="label">{'Leita'}</span>
									<span className="icon"><img src={searchIcon} /></span>
								</button>
							</div>

							<div className="search-info">
								<span className="d-none d-sm-inline">{specialCharLinks}</span>
								<span className="ml-2">Skönnunarvillur verða lagaðar. Mynd við færslu sýnir prentaða textann.</span>
							</div>

							<div className="form-group form-check">
								<ToggleSwitch label={'Textaleit'} onChange={this.inputChangeHandler} name="searchTextInput" value={this.state.searchTextInput} />
								{/*<input type="checkbox"
									onChange={this.inputChangeHandler}
									className="form-check-input"
									checked={this.state.beygingarmyndInput}
									name="beygingarmyndInput"
									id="beygingarmyndInput" />
								<label className="form-check-label" htmlhtmlFor="beygingarmyndInput">Leita að beygingarmynd</label>*/}
							</div>

							<div className="clearfix" />

						</div>

						<div className={'form-group column-content pt-0 mb-4'+(this.state.advanced ? ' d-block' : ' d-none')}>
							<div className="row">

								<div className="col-12 col-sm-4">
									<label className="form-heading">Svæði</label>
									<select name="searchSvaedi"
										value={this.state.searchSvaedi}
										className="form-control"
										onChange={this.inputChangeHandler}
									>
										<option value=""></option>
										{
											helpers.mallyskur.map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)
										}
									</select>
								</div>

								{
									/*
								<div className="col-12 col-sm-4">
									<label className="form-heading">Orðflokkur</label>
									<select name="searchOrdflokkur"
										value={this.state.searchOrdflokkur}
										className="form-control"
										onChange={this.inputChangeHandler}
									>
										<option value=""></option>
										{
											helpers.ordflokkur.map((item, index) => <option key={index} value={item[0]}>{item[1]}</option>)
										}
									</select>
								</div>
									*/
								}

								<div className="col">
									<label className="form-heading">Sérmerking</label>

									<div>

										<div className="form-check form-check-inline">
											<input className="form-check-input"
												onChange={this.inputChangeHandler}
												type="checkbox"
												id="sermerkingUrelt"
												name="sermerkingUrelt"
												checked={this.state.sermerkingUrelt} />
											<label className="form-check-label" htmlFor="sermerkingUrelt">Úrelt</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input"
												onChange={this.inputChangeHandler}
												type="checkbox"
												id="sermerkingSkaldamal"
												name="sermerkingSkaldamal"
												checked={this.state.sermerkingSkaldamal} />
											<label className="form-check-label" htmlFor="sermerkingSkaldamal">Skáldamál</label>
										</div>


										<div className="form-check form-check-inline">
											<input className="form-check-input"
												onChange={this.inputChangeHandler}
												type="checkbox"
												id="sermerkingNyyrdi"
												name="sermerkingNyyrdi"
												checked={this.state.sermerkingNyyrdi} />
											<label className="form-check-label" htmlFor="sermerkingNyyrdi">Nýyrði</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input"
												onChange={this.inputChangeHandler}
												type="checkbox"
												id="sermerkingTokuorð"
												name="sermerkingTokuorð"
												checked={this.state.sermerkingTokuorð} />
											<label className="form-check-label" htmlFor="sermerkingTokuorð">Tökuorð</label>
										</div>

										{
											/*
										<div className="form-check form-check-inline">
											<input className="form-check-input"
												onChange={this.inputChangeHandler}
												type="checkbox"
												id="sermerkingDaglegtmal"
												name="sermerkingDaglegtmal"
												checked={this.state.sermerkingDaglegtmal} />
											<label className="form-check-label" htmlFor="sermerkingDaglegtmal">Daglegt mál</label>
										</div>
											*/
										}
									</div>
								</div>

							</div>
						</div>

					</div>

					<div className="loading-bar">
						<div className="indicator" />
					</div>
				</div>
				{/*</StickyWatcher>*/}
			</React.Fragment>
		);
	}
}

export default withRouter(SearchBox);
