import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import SearchBox from './SearchBox';

import config from './../config';

class FrontPage extends Component {
	constructor(props) {
		super(props);

		this.url = config.vefurRoot+'/api/front_sections/';

		this.htmlContentClickHandler = this.htmlContentClickHandler.bind(this);


		this.state = {
			data: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		let site = this.props.site || 1;

		fetch(this.url+'?site='+site)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: json.results
				});


				if (site && document.getElementById('word_count_el')) {
					this.updateWordCounterElement();
				}
			}.bind(this));
	}

	updateWordCounterElement() {
		fetch('/php_bin/fjoldi_orda.php')
			.then(function(response) {
				return response.text();
			})
			.then(function(text) {
				document.getElementById('word_count_el').innerHTML = text;
			});
	}

	formatHtml(html) {
		let formatedHtml = html.replace(/\\r|\\n/g, '');

		return formatedHtml;
	}

	isExternal(url) {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (url.toLowerCase().indexOf('.pdf') > -1) return true;
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;
		return false;
	}

	htmlContentClickHandler(event) {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');
			if (!this.isExternal(linkUrl)) {
				event.preventDefault();
				this.props.history.push(linkUrl);
			}
		}
	}

	render() {
		console.log(this.props)
		return (
			<React.Fragment>
				{
					this.state.data && this.state.data.length > 0 &&
					<div className={'row front-banner'}>

						{
							this.state.data.map(function(section, index) {
								if (section.css_class == 'search-box') {
									return <div key={index} className={'col col-12 mt-4'}>
										<SearchBox className={'col col-12'} />
									</div>
								}
								else {
									return <div key={index} className={'col '+section.css_class}>
										<div className="column-content"
											onClick={this.htmlContentClickHandler}
											dangerouslySetInnerHTML={{__html: this.formatHtml(section.content)}}/>
									</div>
								}
							}.bind(this))
						}

					</div>
				}
			</React.Fragment>
		);
	}
}

export default withRouter(FrontPage);
