import _ from 'underscore';

const helpers = {
	parseSearchParams: (paramStr) => {
		let params = {};

		paramStr = decodeURI(paramStr);

		if (paramStr.substr(0, 1) == '?') {
			paramStr = paramStr.substr(1);
		}

		if (paramStr.split('&').length > 0) {
			paramStr.split('&').forEach((item, i) => {
				if (item.split('=')[0]) {
					params[item.split('=')[0]] = item.split('=')[1];
				}
			});
		}

		return params;
	},

	searchParamString: (params) => {
		let searchParams = [];

		for (var param in params) {
			searchParams.push(param+'='+
				(Array.isArray(params[param]) ? (
					params[param].map((item) => item.id || item).join(',')
				) : params[param])
			);
		}

		return '?'+searchParams.join('&');
	},

	updateSearchParams: (paramStr, newParams) => {
		let currentParams = helpers.parseSearchParams(paramStr);

		for (var param in newParams) {
			currentParams[param] = newParams[param];
		}

		let searchParams = [];

		for (var param in currentParams) {
			if (currentParams[param] != undefined && currentParams[param] != '') {
				searchParams.push(param+'='+
					(Array.isArray(currentParams[param]) ? (
						currentParams[param].map((item) => item.id || item).join(',')
					) : currentParams[param])
				);
			}
		}

		return '?'+searchParams.join('&');
	},

	bokNames: {
		vidbaetir: 'viðbætir',
		fyrsta: 'orðabók'
	},

	getBokName: function(bok) {
		return this.bokNames[bok] || bok;
	},

	getMallyska: function(mallyska) {
		let item = _.find(this.mallyskur, (item) => item[0] == mallyska);
		return item ? item[1] : mallyska;
	},

	capitalize: (s) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1)
	},

	sermerkingSymbols: {
		urelt: '†',
		skaldamal: '*',
		nyyrdi: '°',
		tokuord: '?'
	},

	mallyskur: [
		['Af.', 'Austfirðir', 'Østlandet'],
		['Ak.', 'Akureyri', 'By paa Nordlandet'],
		['Álft.', 'Álftafjörður', 'Egn paa Østlandet'],
		['Árn.', 'Árnessýsla', 'Syssel paa Sydlandet'],
		['Arnf.', 'Arnarfjörður', 'Egn i det nordvestlige Island'],
		['ASkaft.', 'Austur-Skaftafellssýsla', 'Syssel paa Sydlandet'],
		['Axarf.', 'Axarfjörður', 'Egn paa Nordlandet'],
		['Barð.', 'Barðastrandarsýsla', 'Syssel i det nordvestlige Island'],
		['Berufj.', 'Berufjörður', 'Egn i Øst-Island'],
		['Borg.', 'Borgarfjarðarsýsla', 'Syssel i det vestlige Island'],
		['Breiðd.', 'Breiðdalur', 'Egn paa Østlandet'],
		['Breiðf.', 'Breiðafjörður', 'Egn i det vestlige Island'],
		['Dal.', 'Dalasýsla', 'Syssel i det vestlige Island'],
		['Df.', 'Dýrafjörður', 'Egn i det nordvestlige Island'],
		['Dýrf.', 'Dýrafjörður', 'Egn i det nordvestlige Island'],
		['Eyf.', 'Eyjafjörður', 'Egn paa Nordlandet'],
		['Gullbr.', 'Gullbringusýsla', 'Syssel i det sydvestlige Island'],
		['Gull.', 'Gullbringusýsla', 'Syssel i det sydvestlige Island'],
		['Hf.', 'Hornafjörður', 'Egn i det sydøstlige Island'],
		['Hnapp.', 'Hnappadalssýsla', 'Syssel i det vestlige Island'],
		['Hornstr.', 'Hornstrandir', 'Egn i det nordvestlige Island'],
		['Hún.', 'Húnavatnssýsla', 'Syssel paa Nordlandet'],
		['Hvolhr.', 'Hvolhreppur', 'Egn paa Sydlandet'],
		['Ísf.', 'Ísafjarðarsýsla og -kaupstaður', 'Syssel og Købstad i det nordvestlige Island'],
		['Kjós.', 'Kjósarsýsla', 'Syssel i det sydvestlige Island'],
		['Langan.', 'Langanes', 'Egn i det nordøstlige Island'],
		['Mýr.', 'Mýrasýsla', 'Syssel i det vestlige Island'],
		['Mýrd.', 'Mýrdalur', 'Egn paa Sydlandet'],
		['Mýv.', 'Mývatnssveit', 'Egn paa Nordlandet'],
		['Mosf.', 'Mosfellssveit', 'Egn i Nærheden af Reykjavík'],
		['Múl.', 'Múlasýslur', 'Sysler paa Østlandet'],
		['Nl.', 'Norðurland', 'Nordlandet'],
		['NMúl.', 'Norður-Múlasýsla', 'Syssel paa Østlandet'],
		['Norðf.', 'Norðfjörður', 'Egn paa Østlandet'],
		['NÞing.', 'Norður-Þingeyjarsýsla', 'Syssel paa Nordøstlandet'],
		['Rang.', 'Rangárvallasýsla', 'Syssel paa Sydlandet'],
		['Reyðarf.', 'Reyðarfjörður', 'Egn paa Østlandet'],
		['Rvk.', 'Reykjavík', ''],
		['Seyðf.', 'Seyðisfjörður', 'Egn og Købstad paa Østlandet'],
		['Siglf.', 'Siglufjörður', 'Købstad paa Nordlandet'],
		['Skaft.', 'Skaftafellssýslur', 'Sysler paa Sydlandet'],
		['Skag.', 'Skagafjarðarsýsla', 'Syssel paa Nordlandet'],
		['Sl.', 'Suðurland', 'Sydlandet'],
		['SMúl.', 'Suður-Múlasýsla', 'Syssel paa Østlandet'],
		['Snæf.', 'Snæfellsnessýsla', 'Syssel i det vestlige Island'],
		['Strand.', 'Strandasýsla', 'Syssel i det nordlige Island'],
		['Súgandaf.', 'Súgandafjörður', 'Egn paa Vestlandet'],
		['Vestm.', 'Vestmannaeyjar', 'Købstad paa en Ø syd for Island'],
		['Vf.', 'Vestfirðir', 'den nordvestlige Del af Landet'],
		['Vopnf.', 'Vopnafjörður', 'Egn paa Østlandet'],
		['VSkaft.', 'Vestur-Skaftafellssýsla', 'Syssel paa Sydlandet'],
		['Þing.', 'Þingeyjarsýslur', 'Sysler paa Nordlandet'],
		['Þingv.', 'Þingvallasveit', 'Egn paa Sydlandet'],
	],

	getFormdeild: function(mallyska, falseIfNotFound) {
		let item = _.find(this.formdeild, (item) => item[0] == mallyska);
		return falseIfNotFound ? (item ? item[1] : false) : (item ? item[1] : mallyska);
	},

	formdeild: [
		['a.', 'lýsingarorð'],
		['abs.', 'absolutus'],
		['adj', 'lýsingarorð'],
		['adj', 'lýsingarorð'],
		['adj.', 'lýsingarorð'],
		['adj.', 'lýsingarorð'],
		['adj.comp.', 'lýsingarorð í miðstigi'],
		['adj.f.', 'kvenkyns lýsingarorð'],
		['adj. indec.', 'óbeygjanlegt lýsingarorð'],
		['adj.indec.', 'óbeygjanlegt lýsingarorð'],
		['adj.Indec.', 'óbeygjanlegt lýsingarorð'],
		['adj. n.', 'hvorugkyns lýsingarorð'],
		['adj. og adv.', 'lýsingarorð | atviksorð'],
		['(adv.)', 'lýsingarorð'],
		['adv.', 'atviksorð'],
		['adv. comp. og præp. m. dat.', 'lýsingarorð í miðstigi | forsetning með þágufalli'],
		['conj.', 'viðtengingarháttur'],
		['f', 'kvenkyns'],
		['f.', 'kvenkyns'],
		['f.', 'kvenkyns'],
		['f. el n.', 'kvenkyns | hvorugkyns'],
		['f.indec.', 'óbeygjanlegt kvenkyns'],
		['f. og m.', 'kvenkyns | karlkyns'],
		['f. og npl', 'kvenkyns | hvorugkyns fleirtala'],
		['fpl', 'kvenkyns fleirtala'],
		['fpl.', 'kvenkyns fleirtala'],
		['fpl.', 'kvenkyns fleirtala'],
		['interj.', 'upphrópun'],
		['Interj.', 'upphrópun'],
		['m', 'karlkyns'],
		['m.', 'karlkyns'],
		['m.', 'karlkyns'],
		['m. el. f.', 'karlkyns | kvenkyns'],
		['m. el. fpl.', 'karlkyns | kvenkyns fleirtala'],
		['m.', 'karlkyns'],
		['m.(f.)', 'karlkyns (kvenkyns)'],
		['m.', 'karlkyns'],
		['m. og n.', 'karlkyns | hvorugkyns'],
		['(mpl.)', '(karlkyns fleirtala)'],
		['mpl.', 'karlkyns fleirtala'],
		['mpl.', 'karlkyns fleirtala'],
		['mpl.', 'karlkyns fleirtala'],
		['(n.)', '(hvorugkyns)'],
		['n', 'hvorugkyns'],
		['n.', 'hvorugkyns'],
		['n.', 'hvorugkyns'],
		['n. el. f.', 'hvorugkyns | kvenkyns'],
		['n. (f.)', 'hvorugkyns (kvenkyns)'],
		['n.', 'hvorugkyns'],
		['n.(f.)', 'hvorugkyns (kvenkyns)'],
		['n. (m.)', 'hvorugkyns (karlkyns)'],
		['n.', 'hvorugkyns'],
		['npl', 'hvorugkyns fleirtala'],
		['npl.', 'hvorugkyns fleirtala'],
		['npl.', 'hvorugkyns fleirtala'],
		['num. ord', 'raðtala'],
		['pp.', 'lýsingarháttur þátíðar'],
		['præp.', 'forsetning'],
		['v', 'sagnorð'],
		['v.', 'sagnorð'],
		['v.', 'sagnorð'],
		['vi.', 'áhrifslaus sögn'],
		['v. impers.', 'ópersónuleg sögn'],
		['v. ', 'sagnorð'],
		['     impers. m. dat.', 'ópersónuleg sögn með þágufalli'],
		['v. impers. m. dat.', 'ópersónuleg sögn með þágufalli'],
		['v. m. dat.', 'sögn með þágufalli'],
		['vrefl.', 'afturbeygð sögn'],
		['vrefl. m. dat.', 'afturbeygð sögn með þágufalli'],
		['vt.', 'áhrifssögn']
	]
}

export default helpers;
