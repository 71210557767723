import React, { Component, useState, useEffect } from 'react';
import { NavLink, Link, useHistory, withRouter } from "react-router-dom";

import SearchResultItem from './SearchResultItem';
import HeimildView from './HeimildView';

import config from './../config';
import helpers from './../helpers';

import _ from 'underscore';

class SearchResultsEntryList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/faersla/';

		this.state = {
			data: null,
			fetching: true,
			initialized: false,
			filterData: {},
			selectedFilter: {}
		};

		this.filterChangeHandler = this.filterChangeHandler.bind(this);
	}

	componentDidMount() {
		let params = helpers.parseSearchParams(this.props.searchParams);

		this.setSelectedFilters();

		if (this.props.searchParams) {
			this.fetchData(true);
		}
	}

	setSelectedFilters() {
		let params = helpers.parseSearchParams(this.props.searchParams);

		let selectedFilter = {};

		for (let param in params) {
			if (param == 'sia_sermerking') {
				params[param].split(';').forEach((item, i) => {
					selectedFilter[param+item] = true;
				});

			}
			else if (param.startsWith('sia_')) {
				selectedFilter[param] = params[param];
			}
		}

		this.setState({
			selectedFilter: selectedFilter
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(
				prevProps.searchParams != this.props.searchParams
			)
		) {
			this.fetchData(true);
		}
	}

	fetchData(newSearch, keepFilters) {
		window.scrollTo(0, 0);


		if (this.props.onLoadStart) {
			this.props.onLoadStart();
		}

		let stateObj = {
			data: null,
			fetching: true,
			initialized: false,
		};

		if (newSearch) {
			//stateObj.filterData = {};

			stateObj.selectedFilter = {};
		}

		this.setState(stateObj);

		this.setSelectedFilters();

		let urlParams = [];

		let params = helpers.parseSearchParams(this.props.searchParams);

		if (params.texti) {
			urlParams.push('leit='+params.texti.split('*').join(''));

			window.document.title = config.siteTitle+' | '+params.texti;
		}

		if (params.leit) {
			urlParams.push('ordafordi='+params.leit);

			window.document.title = config.siteTitle+' | '+params.leit;
		}

		if (params.heimild) {
			urlParams.push('heimild='+params.heimild);
		}
		if (params.ordflokkur || params.sia_ordflokkur) {
			urlParams.push('ordflokkur='+(params.sia_ordflokkur || params.ordflokkur));
		}
		if (params.svaedi || params.sia_svaedi) {
			urlParams.push('svaedi='+(params.sia_svaedi || params.svaedi));
		}
		if (params.sida) {
			urlParams.push('offset='+((params.sida-1)*config.pageSize));
		}
		if (params.sermerkingar || params.sia_sermerking) {
			urlParams.push('sermerkingar='+[params.sia_sermerking, params.sermerkingar].join(';'));
		}

		//if (this.props.beygingarmynd) {
		//	urlParams.push('ordmyndir=on');
		//}

		if (newSearch) {
			this.fetchFilterData(urlParams);
		}

		fetch(this.url+'?'+urlParams.join('&'))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {

				this.setState({
					data: json.results,
					fetching: false,
					nextUrl: json.next,
					prevUrl: json.previous,
					resultsCount: json.count
				});
				/*
				if (json.results.length == 1) {
					this.props.history.push('/faersla/'+json.results[0].id+(this.getHighlight() ? '/merkja/'+this.getHighlight() : ''));
				}
				else {
					this.setState({
						data: json.results,
						fetching: false,
						nextUrl: json.next,
						prevUrl: json.previous,
						resultsCount: json.count
					});
				}
				*/

				setTimeout(function() {
					this.setState({
						initialized: true
					});

					try {
						if (this.props.history.location.hash && this.props.history.location.hash.length > 0 && this.props.history.location.hash.startsWith('#listi/')) {
							let listItemId = this.props.history.location.hash.replace('#listi/', '');

							document.getElementById('faersla-'+listItemId).scrollIntoView({
								behavior: 'instant'
							});

							document.getElementById('faersla-'+listItemId).classList.add('item-highlight');

							setTimeout(() => {
								document.getElementById('faersla-'+listItemId).classList.remove('item-highlight');
							}, 400);
						}
					}
					catch(e) {}
				}.bind(this), 200);

				if (this.props.onLoadFinish) {
					this.props.onLoadFinish();
				}
			}.bind(this));

		if (this.props.onSearch) {
			this.props.onSearch(this.props.match.params);
		}

		if (this.props.onSearch) {
			this.props.onSearch({
				searchString: this.props.match.params.searchString
			});
		}
	}

	fetchFilterData(urlParams) {
		fetch(config.apiRoot+'/api/mallyskur?'+urlParams.join('&'))
			.then((res) => res.json())
			.then((json) => {
				let svaedi = [];

				json.results.forEach((item, i) => {
					svaedi = svaedi.concat(item.mallyska.replace(/\s+/g, '').split(','));
				});

				let filterData = this.state.filterData;
				filterData.svaedi = svaedi;

				this.setState({
					filterData: filterData
				});
			})

		fetch(config.apiRoot+'/api/formdeild?'+urlParams.join('&'))
			.then((res) => res.json())
			.then((json) => {
				let ordflokkur = [];

				json.results.forEach((item, i) => {
					ordflokkur = ordflokkur.concat(item.formdeild.replace(/\s+/g, '').split(','));
				});

				let filterData = this.state.filterData;
				filterData.ordflokkur = _.filter(ordflokkur, (item) => helpers.getFormdeild(item, true));

				this.setState({
					filterData: filterData
				});
			})

		fetch(config.apiRoot+'/api/sermerkingar?'+urlParams.join('&'))
			.then((res) => res.json())
			.then((json) => {
				let sermerkingar = [];


				sermerkingar = json.results.length > 0 ? json.results.reduce((a, b) => {
					return {
						urelt: b.urelt || a.urelt,
						skaldamal: b.skaldamal || a.skaldamal,
						nyyrdi: b.nyyrdi || a.nyyrdi,
						tokuord: b.tokuord || a.tokuord,
						daglegtmal: b.daglegtmal || a.daglegtmal
					}
				}) : null;

				let filterData = this.state.filterData;
				filterData.sermerkingar = sermerkingar;

				this.setState({
					filterData: filterData
				});
			})
	}

	formatHtml(html) {
		let formatedHtml = html.replace(/\\r|\\n/g, '');

		return formatedHtml;
	}

	getHighlight() {
		let highlight = [];

		let params = helpers.parseSearchParams(this.props.searchParams);

		if (params.leit || params.texti) {
			highlight.push((params.texti || params.leit).split('*').join(''));
		}
		if (params.heimild) {
			highlight.push(params.heimild);
		}
		/*
		if (params.ordflokkur || params.sia_ordflokkur) {
			highlight.push(params.sia_ordflokkur || params.ordflokkur);
		}
		*/
		if (params.svaedi || params.sia_svaedi) {
			highlight.push(params.sia_svaedi || params.svaedi);
		}
		if (params.sermerkingar) {
			highlight = highlight.concat(params.sermerkingar.replace('urelt', helpers.sermerkingSymbols.urelt).replace('skaldamal', helpers.sermerkingSymbols.skaldamal).replace('nyyrdi', helpers.sermerkingSymbols.nyyrdi).split(';'))
			//highlight = highlight.concat(this.props.match.params.sermerkingar.replace('urelt', '†').replace('skaldamal', '*').replace('nyyrdi', '°').replace('tokuord', '?').split(';'))
		}
		if (this.state.selectedFilter) {
			if (this.state.selectedFilter.sermerkingurelt) {
				highlight.push(helpers.sermerkingSymbols.urelt);
			}
			if (this.state.selectedFilter.sermerkingskaldamal) {
				highlight.push(helpers.sermerkingSymbols.skaldamal);
			}
			if (this.state.selectedFilter.sermerkingnyyrdi) {
				highlight.push(helpers.sermerkingSymbols.nyyrdi);
			}
		}
		return _.compact(highlight).join(';');
	}

	filterChangeHandler(event) {
		let params = helpers.parseSearchParams(this.props.searchParams);

		let value = event.target.type && event.target.type == 'checkbox' ? event.target.checked : event.target.value;

		let filterName = event.target.name;

		let selectedFilter = this.state.selectedFilter;

		let searchParams = {};

		if (value == 0 && selectedFilter[filterName]) {
			delete selectedFilter[filterName];
		}
		else {
			selectedFilter[filterName] = value;

			if (!filterName.startsWith('sia_sermerking')) {
				searchParams[filterName] = value;
			}
		}

		let sermerking = [];

		if (this.state.selectedFilter.sia_sermerkingurelt) {
			sermerking.push('urelt');
		}
		if (this.state.selectedFilter.sia_sermerkingnyyrdi) {
			sermerking.push('nyyrdi');
		}
		if (this.state.selectedFilter.sia_sermerkingtokuord) {
			sermerking.push('tokuord');
		}
		if (this.state.selectedFilter.sia_sermerkingskaldamal) {
			sermerking.push('skaldamal');
		}
		if (this.state.selectedFilter.sia_sermerkingdaglegtmal) {
			sermerking.push('daglegtmal');
		}

		searchParams.sia_sermerking = sermerking.join(';');


		let newParams = helpers.updateSearchParams(this.props.searchParams, searchParams);

		this.setState({
			selectedFilter: selectedFilter
		});

		this.props.history.push('/leit/'+newParams);

		/*
		let value = event.target.type && event.target.type == 'checkbox' ? event.target.checked : event.target.value;

		let filterName = event.target.name.replace('filter-', '');

		let selectedFilter = this.state.selectedFilter;

		if (value == 0 && selectedFilter[filterName]) {
			delete selectedFilter[filterName];
		}
		else {
			selectedFilter[filterName] = value;
		}

		this.setState({
			selectedFilter: selectedFilter
		}, () => {
			this.fetchData();
		});
		*/

		/*
		let urlParams = {};
		urlParams[filterName] = value;

		let newUrl = this.buildUrl(Object.assign(Object.assign({}, this.props.match.params), urlParams));

		this.props.history.replace(newUrl);
		*/
	}

	buildPaginatorUrl(page) {
		let newParams = helpers.updateSearchParams(this.props.searchParams, {sida: page});

		return '/leit/'+newParams;

		//return this.buildUrl(Object.assign(Object.assign({}, this.props.match.params), Object.assign(this.state.selectedFilter, {sida: page})));
	}

	printResultCount(count) {
		return count.toString().endsWith('11') ? count.toString()+' niðurstöðum' :
			count.toString().endsWith('1') ? count.toString()+' niðurstöðu' :
			count.toString()+' niðurstöðum';
	}

	render() {
		let pageButtons = [];

		let maxButtonCount = 7;

		let startPage = parseInt(this.props.match.params.sida || 1);

		if (startPage > Math.floor(maxButtonCount/2) && this.state.resultsCount/config.pageSize > maxButtonCount) {
			startPage = startPage-(Math.floor(maxButtonCount/2));
		}
		else {
			startPage = 1;
		}

		let maxPageCount = Math.ceil((this.state.resultsCount/config.pageSize))+1;

		maxPageCount = maxPageCount > startPage+maxButtonCount ? startPage+maxButtonCount : maxPageCount;
		maxPageCount = maxPageCount > this.state.resultsCount/config.pageSize ? Math.ceil(this.state.resultsCount/config.pageSize) : maxPageCount;

		if (startPage > 1) {
			pageButtons.push(
				<div key="1" className="page-item">
					<NavLink className="page-link btn-info" to={this.buildPaginatorUrl(1)} aria-label="Previous">
						<span aria-hidden="true">1</span>
					</NavLink>
				</div>
			);

			if (startPage > 2) {
				pageButtons.push(
					<div key="..." className="page-item">
						<div className="page-link btn-info">
							<span aria-hidden="true">...</span>
						</div>
					</div>
				);
			}
		}

		for (let i = startPage; i<maxPageCount; i++) {
			pageButtons.push(
				<div key={i} className="page-item">
					<NavLink className="page-link btn-info" to={this.buildPaginatorUrl(i)} aria-label="Previous">
						<span aria-hidden="true">{i}</span>
					</NavLink>
				</div>
			);
		}

		if (Math.ceil(this.state.resultsCount/config.pageSize) > maxPageCount) {
			pageButtons.push(
				<div key="...." className="page-item">
					<div className="page-link btn-info">
						<span aria-hidden="true">...</span>
					</div>
				</div>
			);
		}

		pageButtons.push(
			<div key={Math.ceil((this.state.resultsCount/config.pageSize))} className="page-item">
				<NavLink className="page-link btn-info" to={this.buildPaginatorUrl(Math.ceil(this.state.resultsCount/config.pageSize))} aria-label="Previous">
					<span aria-hidden="true">{Math.ceil((this.state.resultsCount/config.pageSize))}</span>
				</NavLink>
			</div>
		);

		let paginationLinks = <React.Fragment>
			{
				this.state.prevUrl &&
				<div className="page-item">
					<Link className="page-link btn-info" to={this.buildPaginatorUrl((this.props.match.params.sida ? (parseInt(this.props.match.params.sida)-1) : ''))} aria-label="Previous">
						<span aria-hidden="true"><span className="arrows">&laquo;</span> Fyrri síða</span>
						<span className="sr-only">Fyrri síða</span>
					</Link>
				</div>
			}
			{
				pageButtons
			}
			{
				this.state.nextUrl &&
				<div className="page-item">
					<Link className="page-link btn-info" to={this.buildPaginatorUrl((this.props.match.params.sida ? (parseInt(this.props.match.params.sida)+1) : 2))} aria-label="Next">
						<span aria-hidden="true">Næsta síða <span className="arrows">&raquo;</span></span>
						<span className="sr-only">Næsta síða</span>
					</Link>
				</div>
			}
		</React.Fragment>;

		let hasFilterData = false;

		for (let filter in this.state.filterData) {
			if (this.state.filterData[filter] && this.state.filterData[filter].length > 0 && !this.props.match.params[filter] && this.props.match.params[filter] != '') {
				hasFilterData = true;
			}
		}

		return (
			<div className="results-list">
				{
					(this.props.match.params.searchString || this.props.match.params.heimild) && this.state.data && this.state.data.length > 0 &&
					<div className="search-results-info">
						Leit {
							this.props.match.params.searchString && this.props.match.params.searchString.length > 0 ? <>að orðinu <em>{this.props.match.params.searchString}</em></> : ''
						} {
							this.props.match.params.heimild ? <>í heimildinni <em><HeimildView heimild={this.props.match.params.heimild} /></em></> : ''
						} skilaði {this.printResultCount(this.state.resultsCount)}. Smelltu á færslu í listanum til að sjá hana alla.
					</div>
				}
				{
					!this.props.match.params.searchString && this.state.data && this.state.data.length > 0 &&
					<div className="search-results-info">
						Leitin skilaði {this.printResultCount(this.state.resultsCount)}. Smelltu á færslu í listanum til að sjá hana alla.
					</div>
				}
				{
					hasFilterData &&
					<div className="mt-2 mb-2 ml-2 mr-2 card filter-card">
						<div className="card-body pt-3 pb-3 pl-4 pr-4">
							<label className="form-heading">Sía niðurstöður</label>
							<div className="row">
								{
									this.state.filterData.svaedi && this.state.filterData.svaedi.length > 0 && !this.props.match.params.svaedi && this.props.match.params.svaedi != '' &&
									<div className="col-12 col-sm-4">
										<label>Svæði:</label>
										<select name="sia_svaedi"
											onChange={this.filterChangeHandler}
											value={this.state.selectedFilter.sia_svaedi || 0}
											className="form-control form-inline"
										>
										<option value="0"></option>
										{
											_.uniq(this.state.filterData.svaedi).map((item, index) => <option value={item} key={index}>{helpers.getMallyska(item)+' ('+item+')'}</option>)
										}
										</select>
									</div>
								}
								{
									this.state.filterData.ordflokkur && this.state.filterData.ordflokkur.length > 0 && !this.props.match.params.ordflokkur && this.props.match.params.ordflokkur != '' &&
									<div className="col-12 col-sm-4">
										<label>Orðflokkur:</label>
										<select name="sia_ordflokkur"
											onChange={this.filterChangeHandler}
											value={this.state.selectedFilter.sia_ordflokkur || 0}
											className="form-control form-inline"
										>
										<option value="0"></option>
										{
											_.uniq(this.state.filterData.ordflokkur).map((item, index) => <option value={item} key={index}>{helpers.getFormdeild(item)+' ('+item+')'}</option>)
										}
										</select>
									</div>
								}
								{
									this.state.filterData.sermerkingar && !this.props.match.params.sermerkingar && this.props.match.params.sermerkingar != '' &&
									<div className="col-12 col-sm-4">
										<label>Sérmerking:</label>
										<div>

											{
												this.state.filterData.sermerkingar.urelt &&
												<div className="form-check form-check-inline">
													<input className="form-check-input"
														onChange={this.filterChangeHandler}
														type="checkbox"
														id="sia_sermerkingurelt"
														name="sia_sermerkingurelt"
														checked={this.state.selectedFilter.sia_sermerkingurelt} />
													<label className="form-check-label" htmlFor="sia_sermerkingurelt">Úrelt</label>
												</div>
											}

											{
												this.state.filterData.sermerkingar.skaldamal &&
												<div className="form-check form-check-inline">
													<input className="form-check-input"
														onChange={this.filterChangeHandler}
														type="checkbox"
														id="sia_sermerkingskaldamal"
														name="sia_sermerkingskaldamal"
														checked={this.state.selectedFilter.sia_sermerkingskaldamal} />
													<label className="form-check-label" htmlFor="sia_sermerkingskaldamal">Skáldamál</label>
												</div>
											}

											{
												this.state.filterData.sermerkingar.nyyrdi &&
												<div className="form-check form-check-inline">
													<input className="form-check-input"
														onChange={this.filterChangeHandler}
														type="checkbox"
														id="sia_sermerkingnyyrdi"
														name="sia_sermerkingnyyrdi"
														checked={this.state.selectedFilter.sia_sermerkingnyyrdi} />
													<label className="form-check-label" htmlFor="sia_sermerkingnyyrdi">Nýyrði</label>
												</div>
											}

											{
												this.state.filterData.sermerkingar.tokuord &&
												<div className="form-check form-check-inline">
													<input className="form-check-input"
														onChange={this.filterChangeHandler}
														type="checkbox"
														id="sia_sermerkingtokuord"
														name="sia_sermerkingtokuord"
														checked={this.state.selectedFilter.sia_sermerkingtokuord} />
													<label className="form-check-label" htmlFor="sia_sermerkingtokuord">Tökuorð</label>
												</div>
											}

											{
												this.state.filterData.sermerkingar.daglegtmal &&
												<div className="form-check form-check-inline">
													<input className="form-check-input"
														onChange={this.filterChangeHandler}
														type="checkbox"
														id="sia_sermerkingdaglegtmal"
														name="sia_sermerkingdaglegtmal"
														checked={this.state.selectedFilter.sia_sermerkingdaglegtmal} />
													<label className="form-check-label" htmlFor="sia_sermerkingdaglegtmal">Daglegt mál</label>
												</div>
											}

										</div>
									</div>
								}
							</div>
						</div>
					</div>
				}
				{
					this.state.data ?
						this.state.data.map((item, index) => {
								return <SearchResultItem key={index} item={item} preview={true} highlight={this.getHighlight()} />
						}) : <div />
				}
				{
					this.props.match.params.searchString && this.state.data && this.state.data.length == 0 &&
					<React.Fragment>
						<p className="mt-4 ml-4 mr-4 mb-4 lead">Leit að orðinu <em>{this.props.match.params.searchString}</em> skilaði engum niðurstöðum.</p>
						{
							!this.props.searchText &&
							<p className="mt-4 ml-4 mr-4 mb-4">Smelltu <Link to={'/leit/texti/'+this.props.match.params.searchString}>hér</Link> til að leita í texta.</p>
						}
					</React.Fragment>
				}
				{
					!this.props.match.params.searchString && this.state.data && this.state.data.length == 0 &&
					<p className="mt-4 ml-4 mr-4 mb-4 lead">Leitin skilaði engum niðurstöðum.</p>
				}
				{
					(this.state.prevUrl || this.state.nextUrl) && this.state.data && this.state.data.length > 0 &&
					<div className="mt-5 mb-3 ml-3 mr-4">
						<div className="pagination">
							{paginationLinks}
						</div>
					</div>
				}
			</div>
		);
	}
}

export default withRouter(SearchResultsEntryList);
