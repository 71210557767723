export default {
	siteTitle: 'Orðabók Sigfúsar Blöndal',
	pageSize: 100,

	apiRoot: '//blondal.arnastofnun.is/django/ordabok',
	//apiRoot: 'http://130.208.108.18:8000/ordabok',

	vefurRoot: '//blondal.arnastofnun.is/django/vefur',
	//vefurRoot: 'http://130.208.108.18:8000/vefur',

	gaTrackingId: 'G-YXJERZFC7'
}
