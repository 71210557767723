import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link, NavLink, Switch, withRouter } from "react-router-dom";

import SearchBox from './components/SearchBox';
import PageMenu from './components/PageMenu';
import PageView from './components/PageView';
import SearchResultsEntryList from './components/SearchResultsEntryList';
import FaerslaView from './components/FaerslaView';
import IndexList from './components/IndexList';
import SiteFooter from './components/SiteFooter';
import GoogleAnalytics from './components/GoogleAnalytics';
import createBrowserHistory from 'history/createBrowserHistory';
import config from './config';

import _ from 'underscore';

import logo from './images/sam-logo.png';
import blondalLogo from './images/blondal-logo.svg';

const sites = {
	IS: 1,
}

class App extends Component {
	constructor(props) {
		super(props);

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);
		this.searchResultsListSearchHandler = this.searchResultsListSearchHandler.bind(this);
		this.loadStartHandler = this.loadStartHandler.bind(this);
		this.loadFinishHandler = this.loadFinishHandler.bind(this);

		this.state = {
			menuOpen: false,
			searchParams: {
				searchString: '',
				beygingarmynd: false
			},
			routePath: '/',
			loading: false
		};
	}

	componentDidMount() {
		// Hér hlustum við eftir breytingu á route
		this.refs.router.history.listen(function(event) {
			this.setState({
				routePath: event.pathname,
				menuOpen: false
			});

			window.scrollTo(0, 0)
		}.bind(this));

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));
	}

	componentDidUpdate(prevProps) {
	}

	menuButtonClickHandler(event) {
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	searchResultsListSearchHandler(searchParams) {
		this.setState({
			searchParams: searchParams
		});
	}

	loadStartHandler() {
		this.setState({
			loading: true
		});
	}

	loadFinishHandler() {
		this.setState({
			loading: false
		});
	}

	render() {
		let routePath = this.refs.router && this.refs.router.history.location.pathname != this.state.routePath ? this.refs.router.history.location.pathname : this.state.routePath;

		let isOnContentPage = routePath != '/';

		let site = 1;
		let siteInitials = 'is';
		let langInitials = 'is';

		if (!this.refs.router) {
			setTimeout(function() {
				this.forceUpdate();
			}.bind(this), 100);
		}

		return (
			<BrowserRouter ref="router" basename="/">

				<div className={'app-container container-fluid'+
					' site-'+siteInitials+
					' site-lang-'+langInitials+
					//(isOnContentPage ? ' searchform-minimized' : '')+
					(this.state.loading ? ' loading' : '')
				}>

					<div className="row d-flex no-gutters">

						<button className="app-menu-button btn-menu" ref="menuButton" onClick={this.menuButtonClickHandler}>
							<div className={'hamburger-icon'+(this.state.menuOpen ? ' open' : ' black')}>
								<div className="icon-middle" />
								<div className="icon-before-after" />
							</div>
						</button>

						<div className={'col-12 col-md-5 col-lg-4 col-xl-3 app-menu'+(this.state.menuOpen ? ' open' : '')}>

							<div className="menu-content" ref="menuContent">

								<button className="menu-close-button btn btn-link" onClick={
									function() {
										this.setState({
											menuOpen: false
										});
									}.bind(this)}>
									<div className="hamburger-icon open">
										<div className="icon-middle" />
										<div className="icon-before-after" />
									</div>
								</button>

								<header>


							        <div className="app-title-container">
							        	<div className="inner">
											<h1 className="app-title"><Link to="/"><img src={blondalLogo} alt="Orðabók Sigfúsar Blöndal" /></Link></h1>
							        	</div>
							        </div>


								</header>

								<div className="page-links">
									<PageMenu site={site} />
								</div>

						        <a className="site-logo" title="Stofnun Árna Magnússonar í íslenskum fræðum" href="http://arnastofnun.is"><img src={logo} className="logo" /></a>

							</div>

						</div>

						<div className="col-12 col-md-7 col-lg-8 col-xl-9 main-column">
							<div className="row">

								<Switch>
									<Route path="/leit/">
										<div className="col col-12">
											<SearchBox searchString={this.state.searchParams.searchString} />
										</div>
									</Route>
									<Route path="(/leit/)?:searchString*(/heimild/)?:heimild*(/svaedi/)?:svaedi*(/ordflokkur/)?:ordflokkur*(/sermerkingar/)?:sermerkingar(/sida/)?:sida*">
										<div className="col col-12">
											<SearchBox searchString={this.state.searchParams.searchString} />
										</div>
									</Route>
									<Route path="/faersla/:faerslaId">
										<div className="col col-12">
											<SearchBox searchString={this.state.searchParams.searchString} />
										</div>
									</Route>
								</Switch>

								<div className="col-12 col-md-12 col-lg-12 col-xl-12">

									<div className="column-content">
										<Switch>
										{
											/*
											<Route exact path="/leit/texti/:searchString" render={(routeProps) => (
												<SearchResultsEntryList searchText={true} onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
											)} />

											<Route exact path="/leit/:searchString" render={(routeProps) => (
												<SearchResultsEntryList onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
											)} />
											*/
										}
											<Route exact strict path="/leit/" render={(routeProps) => {
												return <SearchResultsEntryList onSearch={this.searchResultsListSearchHandler}
													onLoadStart={this.loadStartHandler}
													onLoadFinish={this.loadFinishHandler}
													searchParams={routeProps.location.search}
													{...routeProps} />
											}} />

											<Route exact path="/faersla/:faerslaId/(merkja)?/:merkja?" render={(routeProps) => (
												<div className="row">

													<div className="col-12 col-sm-9">
														<FaerslaView single={true}
															faerslaId={routeProps.match.params.faerslaId}
															highlight={routeProps.match.params.merkja} />
													</div>

													<div className="col-12 col-sm-3">
														<IndexList flid={routeProps.match.params.faerslaId} />
													</div>

												</div>
											)} />

											<Route exact path="/*" render={(routeProps) => (
												<React.Fragment>
													<PageView />
													{
														/*
														isOnContentPage &&
														<Link className="floating-search-button" to="/"></Link>
														*/
													}
												</React.Fragment>
											)} />
										</Switch>
									</div>

								</div>
							</div>

							<SiteFooter />

						</div>

					</div>

					<div className="mobile-header">
						<h1>Beygingarlýsing íslensks nútímamáls</h1>
					</div>

					<GoogleAnalytics />

				</div>

			</BrowserRouter>
		);
	}
}

export default App;
