import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

//import SimilarEntries from './SimilarEntries';
import HeimildView from './HeimildView';

import config from './../config';
import helpers from './../helpers';

class FaerslaView extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/faersla/';

		this.htmlContentClickHandler = this.htmlContentClickHandler.bind(this);
		this.htmlContentMouseOverHandler = this.htmlContentMouseOverHandler.bind(this);
		this.htmlContentMouseOutHandler = this.htmlContentMouseOutHandler.bind(this);

		this.state = {
			initialized: true,
			display: 'faersla'
		};
	}

	componentDidMount() {
		if (this.props.faerslaId) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// Athuga hvort breytur hafi breyst og hvort component eigi að uppfærast
		if (
			(this.props.faerslaId && this.props.faerslaId != prevProps.faerslaId) ||
			(this.props.data && this.props.data != prevProps.data)
		) {
			this.fetchData();
		}
	}

	fetchData() {
		window.scrollTo(0, 0);

		// Sæki gögn til APA
		fetch(this.url+this.props.faerslaId)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: json,
					notFound: !json.id
				});

				try {
					if (json.ordafordi && json.ordafordi.length > 0) {
						window.document.title = config.siteTitle+' | '+(json.ordafordi.length > 3 ? json.ordafordi.slice(0, 3).join(', ') : json.ordafordi.join(', '));
					}
				}
				catch(e) {
				}

				/*
				setTimeout(function() {
					this.setState({
						initialized: true
					});
				}.bind(this), 200);
				*/
			}.bind(this));
	}

	formatHtml(html) {
		let formatedHtml = (html || '').replace(/\\r|\\n/g, '');

		if (this.props.highlight) {
			let highlight = this.props.highlight.toLowerCase().split(';');

			highlight.forEach((item, i) => {
				let re = new RegExp('(?!<.*?)'+(item == '*' ? '\\'+item : item)+'(?![^<>]*?>)', 'i');

				formatedHtml = formatedHtml.split(re).join('<span class="highlight">'+item+'</span>')
			});
		}

		if (this.props.preview) {
			let truncated = this.truncateHTML(formatedHtml, 400);
			formatedHtml = truncated+(truncated.length < formatedHtml.length ? '...' : '')+'<div class="mt-2"><span class="badge badge-secondary">Meira</span></div>'
		}

		return formatedHtml;
	}

	truncateHTML(text, length) {
		function reverseHtml(str) {
			var ph = String.fromCharCode(206);
			var result = str.split('').reverse().join('');
			while (result.indexOf('<') > -1) {
				result = result.replace('<',ph);
			}
			while (result.indexOf('>') > -1) {
				result = result.replace('>', '<');
			}
			while (result.indexOf(ph) > -1) {
				result = result.replace(ph, '>');
			}
			return result;
		}

		var truncated = text.substring(0, length);
		// Remove line breaks and surrounding whitespace
		truncated = truncated.replace(/(\r\n|\n|\r)/gm,"").trim();
		// If the text ends with an incomplete start tag, trim it off
		truncated = truncated.replace(/<(\w*)(?:(?:\s\w+(?:={0,1}(["']{0,1})\w*\2{0,1})))*$/g, '');
		// If the text ends with a truncated end tag, fix it.
		var truncatedEndTagExpr = /<\/((?:\w*))$/g;
		var truncatedEndTagMatch = truncatedEndTagExpr.exec(truncated);
		if (truncatedEndTagMatch != null) {
			var truncatedEndTag = truncatedEndTagMatch[1];
			// Check to see if there's an identifiable tag in the end tag
			if (truncatedEndTag.length > 0) {
				// If so, find the start tag, and close it
				var startTagExpr = new RegExp(
					"<(" + truncatedEndTag + "\\w?)(?:(?:\\s\\w+(?:=([\"\'])\\w*\\2)))*>");
				var testString = truncated;
				var startTagMatch = startTagExpr.exec(testString);

				var startTag = null;
				while (startTagMatch != null) {
					startTag = startTagMatch[1];
					testString = testString.replace(startTagExpr, '');
					startTagMatch = startTagExpr.exec(testString);
				}
				if (startTag != null) {
					truncated = truncated.replace(truncatedEndTagExpr, '</' + startTag + '>');
				}
			} else {
				// Otherwise, cull off the broken end tag
				truncated = truncated.replace(truncatedEndTagExpr, '');
			}
		}
		// Now the tricky part. Reverse the text, and look for opening tags. For each opening tag,
		//  check to see that he closing tag before it is for that tag. If not, append a closing tag.
		var testString = reverseHtml(truncated);
		var reverseTagOpenExpr = /<(?:(["'])\w*\1=\w+ )*(\w*)>/;
		var tagMatch = reverseTagOpenExpr.exec(testString);
		while (tagMatch != null) {
			var tag = tagMatch[0];
			var tagName = tagMatch[2];
			var startPos = tagMatch.index;
			var endPos = startPos + tag.length;
			var fragment = testString.substring(0, endPos);
			// Test to see if an end tag is found in the fragment. If not, append one to the end
			//  of the truncated HTML, thus closing the last unclosed tag
			if (!new RegExp("<" + tagName + "\/>").test(fragment)) {
				truncated += '</' + reverseHtml(tagName) + '>';
			}
			// Get rid of the already tested fragment
			testString = testString.replace(fragment, '');
			// Get another tag to test
			tagMatch = reverseTagOpenExpr.exec(testString);
		}
		return truncated;
	}

	htmlContentClickHandler(event) {
		event.preventDefault();

		if (this.props.preview) {
			return;
		}

		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkHref = linkEl.getAttribute('href');

			if (linkHref.indexOf('LINK_thyding_') > -1) {
				this.props.history.push('/leit/?texti='+linkHref.replace('LINK_thyding_', ''));
			}

			if (linkHref.indexOf('LINK_visun_') > -1) {
				this.props.history.push('/leit/?leit='+linkHref.replace('LINK_visun_', ''));
			}

			if (linkHref.indexOf('LINK_heimild_') > -1) {
				this.props.history.push('/leit/?heimild='+linkHref.replace('LINK_heimild_', ''));
			}
			if (linkHref.indexOf('LINK_mallyska_') > -1) {
				this.props.history.push('/leit/?svaedi='+linkHref.replace('LINK_mallyska_', ''));
			}
			if (linkHref.indexOf('LINK_formdeild_') > -1) {
				this.props.history.push('/leit/?ordflokkur='+linkHref.replace('LINK_formdeild_', ''));
			}
		}
	}

	htmlContentMouseOverHandler(event) {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkHref = linkEl.getAttribute('href');

			if (linkHref.indexOf('LINK_heimild_') > -1) {
				this.waitForMouseOver = true;

				let clientX = event.clientX;
				let clientY = event.clientY;

				this.mouseOverTimeout = setTimeout(() => {
					if (this.waitForMouseOver) {
						this.setState({
							mouseOverHeimild: linkHref.replace('LINK_heimild_', ''),
							mouseOverX: clientX,
							mouseOverY: clientY
						});

						this.waitForMouseOver = false;
					}
				}, 200);
			}
		}
	}

	htmlContentMouseOutHandler(event) {
		this.waitForMouseOver = false;

		clearTimeout(this.mouseOverTimeout);

		this.setState({
			mouseOverHeimild: null
		});
	}

	render() {
		let dataItem = this.state.data || this.props.data || null;

		return (
			<div className={'dict-entry'}>
				{
					!this.state.notFound && dataItem &&
					<>

						{
							this.props.single &&
							<div className="entry-tabs">
								<a className={this.state.display == 'faersla' ? 'selected' : ''}
								href="#"
								onClick={(event) => {
									event.preventDefault();

									this.setState({
										display: 'faersla'
									});
								}}>Texti</a>
								<a className={this.state.display == 'mynd' ? 'selected' : ''}
								href="#"
								onClick={(event) => {
									event.preventDefault();

									this.setState({
										display: 'mynd'
									});
								}}>Mynd</a>
							</div>
						}

						<div className={'entry-content '+(this.state.display == 'faersla' ? 'd-block' : 'd-none')}>
							<p onClick={this.htmlContentClickHandler}
								onMouseOver={this.htmlContentMouseOverHandler}
								onMouseOut={this.htmlContentMouseOutHandler}
								dangerouslySetInnerHTML={{__html: this.formatHtml(dataItem.fletta_html_nota)}}/>
						</div>

						{
							this.props.single &&
							<div className={'entry-content '+(this.state.display == 'mynd' ? 'd-block' : 'd-none')}>
								<div className="entry-image">
									<img src={'http://blondal.arnastofnun.is/faerslumyndir/'+dataItem.flettumynd} />
								</div>
							</div>
						}

						{
							this.props.single &&
							<div className="entry-meta">
								{helpers.capitalize(helpers.getBokName(dataItem.bok))}, bls. {dataItem.bladsida}
							</div>
						}

						{
							/*
							this.props.single &&
							<SimilarEntries faerslaId={dataItem.id} />
							*/
						}

						{
							this.state.mouseOverHeimild &&
							<div style={{
								top: this.state.mouseOverY+15,
								left: this.state.mouseOverX
							}} className="entry-mouseover">
								<HeimildView heimild={this.state.mouseOverHeimild} faersla={dataItem.id} />
							</div>
						}

					</>
				}
				{
					this.state.notFound &&
					<div className="page-content text-center">
						<h2>Færsla finnst ekki</h2>
						<p>Þessi færsla finnst ekki.</p>
					</div>
				}
			</div>
		);
	}
}

export default withRouter(FaerslaView);
